<template>
  <div id="app">
    <router-view />

  </div>
</template>

<script>
import DevicePixelRatio from './some';
// import DevicePixelRatio from './lisb/api'
//在vue生命周期created中添加


// import "./assets/css/global.scss";
export default {
  data() {
    return {
      name: "App",
      type: "light",
    };
  },
  created() {
    // new DevicePixelRatio().init();
  }
};
</script>

<style lang="scss" scoped></style>
