<template>
  <div class="container" ref="outerDom">
    <div class="top">
      <div class="title_box">
        <div class="title">
          用算法定义效率 · 智能控制践行者
          <h1 v-show="false">浙江数翰科技有限公司</h1>
        </div>
      </div>
      <div class="shvideo">
        <video class="videobox" controls>
          <source src="@/assets/video1.mp4" type="video/mp4">
          <!-- Your browser does not support the video tag. -->
        </video>
        <div class="videotext">
          <div class="videotitle">
            数翰科技对话董倩老师：AI如何让工业生产更智能
          </div>
          <div class="videomore" @click="opendialog()">
            查看更多
          </div>
        </div>
        <!-- <h1 v-show="false">浙江数翰科技有限公司</h1> -->
      </div>
      <el-carousel arrow="never">
        <el-carousel-item v-for="item in bglist" :key="item.index" :style="{ 'background': (item.url) }">
          <img :src="item.url" alt="Image">
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="goods">
      <div class="main" v-show="showgoods">
        <div class="goods_left">
          <div class="en_title" :class="showgoods ? 'join' : ''">
            <p>AI Control System</p>
            <!-- <p>Control System</p> -->
          </div>
          <div class="cn_title" :class="showgoods ? 'join1' : ''">
            <p>AI决策控制系统</p>
          </div>
          <div class="goods_info" :class="showgoods ? 'join2' : ''">
            <div>
              <p> 实时监测和智能控制污水处理的各个环节，通过算法模型</p>
              <p> 数据分析与仿真模拟系统的预测，对各单元进行实时感知</p>
              <p> 和预防性调整，确保水厂安全稳定运行。</p>
            </div>
          </div>
          <div :class="showgoods ? 'join3' : ''">

            <div class="goods_icon">
              <div v-for="i in iconlist" class="iconbox">
                <div class="comebox">
                  <div class="icon">
                    <img :src="i.url" :class="'img' + i.index" alt="">
                  </div>
                  <div class="icon_text">
                    {{ i.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="showgoods ? 'join4' : ''">
            <div class="goods_more">
              <div class="morebox">
                <div class="tomore" @click="gotomore()"><img src="../assets/jiantou.png" alt="">了解更多</div>
              </div>
            </div>
          </div>
          <div class="shuhanbg">
            <img src="../assets/SHUHAN.png" alt="">
          </div>
        </div>
        <div class="goods_right">
          <div class="right_title" :class="showgoods ? 'infojoin' : ''">
            核心产品
          </div>
          <div class="goods_Carousel" :class="showgoods ? 'infojoin' : ''">
            <!-- <span class="demonstration">默认 Hover 指示器触发</span> -->
            <el-carousel indicator-position="none" arrow="never">
              <el-carousel-item v-for="item in goodsimg" :key="item">
                <img :src="item.url" alt="">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>

    </div>
    <div class="technology">
      <div class="main" v-show="showtechnology">

        <div class="technology_title" :class="showtechnology ? 'join' : ''">
          核心技术
        </div>
        <!-- <div style="height: 180px;" v-show="!showtechnology"></div> -->
        <div class="technology_info" :class="showtechnology ? 'infojoin' : ''" v-show="showtechnology">
          <div v-for="i in technologylist" class="iconbox" @mouseenter="choseone(i)" @mouseleave="leavone(i)"
            :class="clickindex === i.index ? 'chosetec' : ''">
            <div class="comebox">
              <div class="icon">
                <img :src="i.url" alt="">
              </div>
              <div class="icon_text">
                <p class="no">{{ i.data }}.</p>
                <p class="name">
                  {{ i.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="technology_text" :class="showtechnology ? 'join' : ''">
          <span>{{ technologytext }}</span>
        </div>
      </div>
    </div>
    <div class="process">
      <div class="main" v-show="showprocess">
        <div class="process_title" :class="showprocess ? 'goup' : ''">
          适用工艺
        </div>
        <div class="process_box" :class="showprocess ? 'goup' : ''">
          <div :class="processhover ? hoverindex == i.index ? 'hoversomebox' : 'antherhover' : 'process_somebox'"
            v-for="i in processlist" @mouseenter="hoverprocess(i)" @mouseleave="leaveprocess()">
            <div class="comebox" v-show="hoverindex !== i.index">
              <div class="icon_text">
                <p class="name">
                  {{ i.name }}
                </p>
                <div class="line">
                </div>
              </div>
            </div>
            <div class="morebox" v-show="hoverindex == i.index">
              <div class="hover_text">
                <p class="name">
                  {{ i.name }}
                </p>
                <p class="littlename" v-for="item in i.data">
                  {{ item }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="partner">
      <div class="main">
        <div class="partner_title" :class="showpartner ? 'goup' : ''">
          合作伙伴
        </div>

        <div class="module_swiper" id="swiper-top">

          <div class="theSwiper" ref="swiper">
            <swiper :options="swiperOption" class="swiper-wrapper">
              <swiper-slide v-for="(item, index) in firmlist" :key="item.index">
                <a :href="item.goto" target="_blank" :alt="item.name">
                  <!-- <img border="0" src="/i/eg_buttonnext.gif" /> -->
                  <img :src="item.url" :alt="item.name" class="logoimg">
                </a>
              </swiper-slide>
              <!-- <div class="swiper-navigation" slot="navigation"></div> -->
            </swiper>
          </div>
          <div slot="button-prev" :class="'swiper-button-prev'
            "></div>
          <div slot="button-next" :class="'swiper-button-next'
            "></div>
        </div>
      </div>

    </div>
    <div class="footer">

      <myfoooter></myfoooter>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="40%" top="80px" :before-close="handleClose">
      <div slot="title" class="header-title">
        <span class="title-text">数翰科技对话董倩老师：AI如何让工业生产更智能</span>
      </div>
      <div>
        <video class="dialogvideobox" controls>
          <source src="@/assets/video1.mp4" type="video/mp4">
        </video>
      </div>
      <div class="text_box">

        <div class="littletitle">从水开始：AI赋能的成长之路</div>
        <div>数翰科技的故事始于对水的思考。
        </div>
        <div>团队从水处理场景起步，以算法为核心，将AI技术与环保行业应用相结合，通过自主研发智慧水务大模型，正式推出
          <span class="strongspan">
            “AI决策控制系统”，为环保行业提供全栈式解决方案
          </span>
        </div>
        <div class="littletitle">创新引领：探索工业场景的更多可能</div>
        <div>
          数翰科技持续探索人工智能技术的深度应用，通过系统的适应性、智能性、泛化学习能力，针对泛工业过程控制，
          构建从单一场景到多样化应用场景的通用智慧控制系统。
        </div>
        <div>实现AI技术在
          <span class="strongspan"> 煤化工、石油化工、精细化工及新能源 </span>
          等垂直工业场景的高效落地与模式复用，助力企业实现提质增效、工艺重构，推动生产管理变革。


        </div>
        <div>
          感谢《信用中国》，让我们能与更多志同道合的伙伴相遇。
        </div>
        <div style="cursor: pointer;" @click="openwatch()">
          <p>
            观看指南：
          </p>
          <p>
            -信用中国电视栏目：《信用中国》20250401期|郭慧：探索工业生产智能化之路
          </p>
          <p>
            -央视（CCTV）手机电视应用程序客户端
          </p>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import myfoooter from "../components/footer.vue";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    myfoooter,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      goodsimg: [
        {
          index: 0,
          url: require("../assets/goods1.png"),
          // name: "物产中大",
        },
        {
          index: 1,
          url: require("../assets/goodui2.png"),
          // name: "港区工业污水厂",
        },

      ],
      bglist: [
        {
          index: 0,
          url: require("../assets/indexbg1 (1).png"),
          name: "物产中大",
        },
        {
          index: 1,
          url: require("../assets/indexbg1 (2).png"),
          name: "港区工业污水厂",
        },
        {
          index: 2,
          url: require("../assets/indexbg1 (3).png"),
          name: "南京化学工业园区",
        },
      ],
      active: 0,
      firmlist: [
        {
          url: require("../assets/wczd.jpg"),
          name: "物产中大",
          goto: 'http://www.wzgroup.cn/'
        },
        {
          url: require("../assets/JPWT.jpg"),
          name: "港区工业污水厂",
        },
        {
          url: require("../assets/nanjing.jpg"),
          name: "南京化学工业园区",
        },
        {
          url: require("../assets/1-23042Q4245YB.png"),
          name: "方洋集团",
          goto: 'http://fygroup.com/'
        }, {
          url: require("../assets/logo/图片2(13).png"),
          name: "中蓝连海",
          goto: 'http://www.lxlhy.chemchina.com/'
        }, {
          url: require("../assets/logo/图片2(18).png"),
          name: "三江化工",
          goto: 'http://www.chinasanjiangfinechemicals.com/about'
        },
        {
          url: require("../assets/图层 1 拷贝.png"),
          name: "华骐环保",
          goto: 'http://www.hqhb.com/'
        },
        {
          url: require("../assets/ziguanghuanbao.png"),
          name: "紫光环保",
          goto: 'https://www.zze.com.cn/'
        },
        {
          url: require("../assets/logo/图片2(1).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(2).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(3).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(14).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(15).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(16).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(17).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(8).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(9).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(10).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(11).png"),
          name: "紫光环保",

        },
        {
          url: require("../assets/logo/图片2(12).png"),
          name: "紫光环保",

        },
      ],
      input: "",
      butchose: 1,
      iconlist: [
        {
          index: 0,
          name: '安全',
          url: require("../assets/anquan.png"),
        },
        {
          index: 1,
          name: '精准',
          url: require("../assets/jingzhun.png"),
        }
        , {
          index: 2,
          name: '经济',
          url: require("../assets/jingji.png"),
        }
      ],
      technologylist: [
        {
          index: 0,
          data: '01',
          name: '仿真系统',
          url: require("../assets/fangzhen.png"),
          text: '利用元学习和进化算法，结合历史规律和当前情况，从策略候选池中不断进行策略模拟、优胜劣汰、繁衍变异的过程，直至筛选出最优的策略。'
        }, {
          index: 1,
          data: '02',
          name: '负荷感知',
          url: require("../assets/fuheganzhi.png"),
          text: '利用贝叶斯优化的原理，不断循环地进行假设验证、预期调整的过程，直到认知收敛化学习科学运筹控制系统评估和预测各工艺段的负荷变化。'
        }, {
          index: 2,
          data: '03',
          name: '负荷分摊',
          url: require("../assets/fuhefentan.png"),
          text: '根据AI所感知的负荷，自动计算负荷转移可行性，根据安全性和经济性实时地做出对应调整，如避免出现曝气过度导致碳源浪费，或碳源投加过量导致后段消耗更多的脱碳费用。负荷分摊技术可灵活适配多个控制单元，实现全局AI控制。'
        },
      ],
      technologytext: null,
      clickindex: 0,
      processlist: [
        {
          index: 0,
          name: '预处理',
          url: require("../assets/anquan.png"),
          data: [
            '混凝沉淀', '气浮', '高效澄清', '芬顿', '...'
          ]
        },
        {
          index: 1,
          name: '厌氧',
          url: require("../assets/jingzhun.png"),
          data: [
            '膨胀颗粒污泥床', '内循环厌氧反应器', '...'
          ]
        },
        {
          index: 2,
          name: '好氧/AO',
          url: require("../assets/jingji.png"),
          data: [
            '好氧颗粒污泥', '活性污泥法', '生物膜法', 'SBR', 'AO&多级AO', '自养反硝化', '...'
          ]
        },
        {
          index: 3,
          name: '深度处理',
          url: require("../assets/jingji.png"),
          data: [
            '混凝沉淀', '气浮', '高效澄清', '芬顿', '臭氧', 'BAF', '...'
          ]
        }
      ],
      processhover: false,
      hoverindex: null,
      swiperOption: {
        loop: true, //首尾循环
        // autoplay: {
        //   delay: 600,
        //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
        //   disableOnInteraction: false, //true：交互后，不继续滚动
        // },
        slidesPerView: 5, //页面中的板块数量
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        freeMode: false, //true:拖到哪就是哪
        spaceBetween: 100, //板块间隔
        grabCursor: false, //true：鼠标为抓手
        // touchStartPreventDefault: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true, //true：点击底部小圆点可以跳转页面
          type: "fraction", //当前页显示 1/7
        },
        prevButton: "#swiper-top .swiper-button-prev",
        nextButton: "#swiper-top .swiper-button-next",
        // 设置点击箭头
        navigation: {
          nextEl: "#swiper-top .swiper-button-next",
          prevEl: "#swiper-top .swiper-button-prev",
        },
      },
      callmelist: [
        {
          index: 0,
          url: require("../assets/wechat.png"),
        },
        {
          index: 1,
          url: require("../assets/email.png"),
          data: 'SH@shuhan.com.cn  '
        },
        {
          index: 2,
          url: require("../assets/icon4.png"),
          data: '0573-88891339'

        },
        {
          index: 3,
          url: require("../assets/icon5.png"),
          data: '浙江省桐乡市环城北路518号智创园楼'

        }
      ],
      wechatshow: false,
      scrollTop: 0, //默认距离顶部的距离
      isShow: false, //控制返回顶部按钮的显隐
      scrollTrigger: false, //默认
      showtechnology: false,
      showprocess: false,
      showpartner: false,
      showgoods: false,
      dialogVisible: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    this.technologytext = this.technologylist[0].text
    console.log(this.technologytext);
    this.showgoods = true
  },
  mounted() {
    window.addEventListener("scroll", this.getScrollTop, true);
  },

  methods: {
    handleScroll() {
      // console.log(123213);
      let scrollall = document.documentElement.clientHeight || document.body.offsetHeight

      let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollall);
      if (scrollTop > scrollall / 2) {
        this.showtechnology = true
      }
      if (scrollTop > scrollall * 1.2) {
        this.showprocess = true
      }
      if (scrollTop > scrollall * 2) {
        this.showpartner = true
      }
    },
    changechose(i) {
      //   console.log(i);
      this.active = i;
    },
    open() {
      this.$alert("这是一段内容", "标题名称", {
        confirmButtonText: "确定",
        callback: (action) => {
          this.$message({
            type: "info",
            message: `action: ${action}`,
          });
        },
      });
    },
    buttonclick() {
      this.butchose = 1;
    },
    buttonclick1() {
      this.butchose = 2;
    },
    choseone(item) {
      console.log(item);
      this.technologytext = item.text
      this.clickindex = item.index
    },
    leavone(item) {
      // this.clickindex = item.index
    },
    hoverprocess(item) {
      this.processhover = true
      this.hoverindex = item.index
      console.log(this.processhover);
    },
    leaveprocess() {
      this.processhover = false
      this.hoverindex = null
    },
    clickcallme(item) {
      console.log(item);
      if (item.index == 0) {
        this.wechatshow = true
      }
    },

    gotomore() {
      // console.log(1222222223);
      this.$store.commit("changeopen", 1);

      this.$router.push('/detail');
    },
    opendialog() {
      this.dialogVisible = true
    },
    openwatch() {
      window.open('https://mp.weixin.qq.com/s/RqwoJbaBv3pFPa8QqUtspw', '_blank')

    }

  },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  display: none;
}

@keyframes changeShow {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0px);
  }
}

@keyframes infoShow {
  from {
    transform: translateX(200%);
  }

  to {
    transform: translateX(0px);
  }
}

.join {
  animation: changeShow 1.2s;
}

.join1 {
  animation: changeShow 1.4s;
}

.join2 {
  animation: changeShow 1.6s;
}

.join3 {
  animation: changeShow 1.8s;
}

.join4 {
  animation: changeShow 2s;
}

.infojoin {
  animation: infoShow 1.2s;
}

.container {
  display: grid;
  overflow: auto;
  height: 100%;
  margin: 0 auto;
  overflow: visible;
  background-color: #fff;


  //   background-color: rgb(0, 0, 0);
  .top {
    position: relative;
    // width: 100vw;
    height: 793px;
    overflow: hidden;

    .title_box {
      position: absolute;
      // background-color: #fff;
      z-index: 12;
      top: 365px;
      width: 100%;
    }

    // .main {
    //   width: 1422px;
    //   margin: 0 auto;
    // }

    .title {
      // position: absolute;
      width: 1544px;
      margin: 0 auto;
      z-index: 12;
      top: 365px;
      left: 15%;
      // width: 1544px;
      height: 24px;
      // margin: 0 auto;
      font-size: 48px;
      font-weight: 400;
      line-height: 24px;
      // background-color: red;
      color: rgba(255, 255, 255, 1);
    }

    .shvideo {
      z-index: 10;
      position: absolute;
      width: 618px;
      height: 400px;
      border-radius: 14px;
      background-color: #fff;
      right: 15%;
      top: 20%;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // text-align: center;

      .videobox {
        position: absolute;
        left: 8px;
        top: 8px;
        width: 600px;
        height: 344px;
        border-radius: 7px;
      }

      .videotitle {
        position: absolute;
        bottom: 14px;
        left: 20px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(36, 102, 168, 1);
        text-align: left;
        vertical-align: top;
      }

      .videomore {
        cursor: pointer;
        position: absolute;
        bottom: 14px;
        right: 24px;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(128, 128, 128, 1);
        text-align: left;
        vertical-align: top;
      }
    }

    img {
      width: 100%;
      height: 793px;
    }

    ::v-deep .el-carousel__indicators {
      position: absolute;
      left: 50%;
      top: 93.5%;
    }

    ::v-deep .el-carousel__container {
      height: 793px !important;
    }

    // background-color: rgb(159, 233, 229);



    .more {
      z-index: 9;
      position: absolute;
      bottom: 0px;
      border-radius: 100% 100% 0 0;
      height: 830px;
      width: 136%;
      background-color: white;
      margin-left: -18%;

      //   overflow: hidden;
      .title {
        width: 100%;
        // z-index: 19;
        color: black;
        position: absolute;
        top: 100px;
        left: 23%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .left {
          font-size: 30px;
          font-weight: 600;

          .name {
            font-size: 46px;
          }
        }

        .right {
          text-align: center;
          //   vertical-align: middle;
          line-height: 54px;
          width: 163px;
          height: 54px;
          font-size: 18px;
          color: white;
          background-color: #057aff;
          border-radius: 54px;
          position: absolute;
          right: 45%;
          bottom: 20px;
        }
      }

      .content {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 23%;
        display: flex;
        flex-direction: row;

        // align-items: center;
        .border {
          width: 37px;

          img {
            width: 100%;
          }

          .yuan {
            width: 37px;
            height: 37px;
            border-radius: 37px;
            border: 1px solid #a6a6a6;
            text-align: center;
            line-height: 37px;

            .point {
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 12px;
              background-color: #4f80bd;
            }
          }

          .solid {
            width: 1px;
            height: 240px;
            background-color: #a6a6a6;
            // border: 1px solid #a6a6a6;
          }
        }

        .core {
          margin-left: 50px;
          letter-spacing: 2.4px;

          div {
            font-size: 16px;
            margin: 30px 0;
            font-weight: 600;

            span {
              color: #3370ff;
              font-weight: 600;
            }
          }

          .first {
            margin-top: 20px;
          }

          .bigtext {
            margin-top: 80px;

            span {
              font-size: 36px;
            }

            .new {
              margin-right: 20px;
            }

            .free {
              padding: 0 20px;
              border-left: 1px solid #a6a6a6;
              border-right: 1px solid #a6a6a6;
            }

            .love {
              margin-left: 20px;
              margin-right: 40px;
            }
          }
        }

        .map {
          position: absolute;
          bottom: -60px;
          right: 38%;
          height: 470px;

          img {
            height: 100%;
          }
        }
      }
    }

    .el-carousel__item h3 {
      color: #475669;
      font-size: 18px;
      opacity: 0.75;
      line-height: 300px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }

  .goods {
    display: flex;
    flex-direction: row;
    height: 849px;
    color: #fff;
    background-color: rgba(255, 255, 255, 1);

    .main {
      width: 1422px;
      margin: 0 auto;
      display: flex;

      p {
        margin: 0 !important;
      }

      .goods_left {
        flex: 1;
        padding-top: 106px;
        // background-color: rgb(214, 106, 106);

        .en_title {
          font-size: 36px;
          font-weight: 200;
          color: rgba(84, 84, 84, 1);
        }

        .cn_title {
          margin-top: 17px;
          font-size: 20px;
          font-weight: 400;
          color: rgba(84, 84, 84, 1);
        }

        .goods_info {
          margin-top: 68px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(112, 112, 112, 1);
        }

        .goods_icon {
          margin-top: 30px;
          width: 250px;
          height: 80px;
          // background-color: red;
          display: flex;

          // .iconbox img:first-child {
          //   width: 21.11px;
          //   height: 26.67px;
          // }

          // .iconbox img:last-child {
          //   width: 30px;
          //   height: 28px;
          // }

          .iconbox {
            flex: 1;

            .comebox {
              width: 56px;

              .img1 {
                width: 30px;
                height: 29px;
                // width: 100%;
              }

              .img2 {
                width: 30px;
                height: 28px;
              }

              .img0 {
                width: 21px;
                height: 27px;
              }
            }

            .icon {
              vertical-align: middle;
              text-align: center;
              line-height: 70px;
              width: 56px;
              height: 51px;
              border-radius: 8px;
              border: 0.6px solid rgba(191, 191, 191, 1);

            }

            // img:first-child {
            //   width: 21.11px;
            //   height: 26.67px;
            // }

            // img:last-child {
            //   width: 30px;
            //   height: 28px;
            // }

            .icon_text {

              // width: 24px;
              height: 30px;
              text-align: center;
              font-size: 12px;
              font-weight: 400;

              line-height: 30px;
              color: rgba(128, 128, 128, 1);


            }
          }
        }

        .goods_more {
          margin-top: 128px;
          width: 200px;
          height: 50px;
          opacity: 1;
          background: rgba(37, 128, 219, 1);
          position: relative;

          .morebox {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 100px;
            height: 24px;
          }

          .tomore {
            img {
              margin-right: 13px;
            }

            font-size: 16px;
            font-weight: 200;
            color: rgba(255, 255, 255, 1);
          }
        }

        .shuhanbg {
          height: 176px;
          margin-top: 50px;

          img {
            height: 100%;
          }
        }
      }

      .goods_right {
        flex: 2;
        // background-color: #057aff;

        .right_title {
          margin-top: 71px;
          // float: right;
          text-align: right;
          font-size: 36px;
          font-weight: 400;
          color: rgba(115, 115, 115, 1)
        }

        .goods_Carousel {
          margin-top: 64px;

          ::v-deep .el-carousel__container {
            height: 468px !important;
          }

          img {
            width: 100%;
          }
        }

      }
    }
  }

  // @keyframes changeShow {
  //   from {
  //     transform: translateX(-100%);
  //   }

  //   to {
  //     transform: translateX(0px);
  //   }
  // }

  // @keyframes infoShow {
  //   from {
  //     transform: translateX(200%);
  //   }

  //   to {
  //     transform: translateX(0px);
  //   }
  // }

  // .join {
  //   animation: changeShow 1.2s;
  // }

  // .infojoin {
  //   animation: infoShow 1.2s;
  // }

  .technology {
    display: flex;
    flex-direction: row;
    height: 745px;
    color: #fff;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/011.jpg.png"); //背景图

    p {
      margin: 0 !important;
    }

    .main {
      width: 1422px;
      margin: 0 auto;
    }

    .technology_title {
      margin-top: 142px;
      width: 144px;
      height: 51px;
      font-size: 36px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }

    .technology_info {
      margin-top: 48px;
      display: flex;

      .chosetec {
        background: linear-gradient(90deg, rgba(140, 142, 163, 1) 0%, rgba(103, 108, 128, 1) 100%);
      }

      .iconbox {
        margin-right: 45px;
        width: 182.66px;
        height: 124px;
        border-radius: 6px;
        border: 1px solid rgba(133, 131, 131, 1);

        .icon {
          margin-top: 12px;
          margin-right: 20px;
          text-align: right;

          img {
            height: 26px;
          }
        }

        .icon_text {
          margin-top: 14px;
          margin-left: 21px;
          font-weight: 400;

          .no {
            font-size: 20px;
          }

          .name {
            font-size: 18px;
          }
        }
      }
    }

    .technology_text {
      margin-top: 107px;
      width: 454px;
      height: 143px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }

  @keyframes titleShow {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateX(0px);
    }
  }

  .goup {
    animation: titleShow 1.2s;

  }

  .process {
    display: flex;
    flex-direction: row;
    height: 983px;
    color: #fff;
    background-color: #fff;

    .main {
      width: 1422px;
      margin: 0 auto;
      // background-color: rgb(106, 162, 214);
    }

    .process_title {
      margin-top: 113px;
      font-size: 36px;
      font-weight: 400;
      color: rgba(115, 115, 115, 1);
    }

    .process_box {
      overflow: hidden;
      margin-top: 89px;
      width: 1422px;
      height: 734px;
      background-image: url("../assets/055.jpg.png"); //背景图
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;

      .hoversomebox {
        flex: 2;
        border-left: 1px solid rgba(255, 255, 255, 1);
        background-color: rgba(0, 0, 0, 0.2);
      }

      .antherhover {
        flex: 1;
        border-left: 1px solid rgba(255, 255, 255, 1);

      }

      .process_somebox {
        flex: 1;
        text-align: center;
        border-left: 1px solid rgba(255, 255, 255, 1);

      }

      .icon_text {
        text-align: center;
        margin-top: 585px;

        .name {
          font-size: 30px;
          font-weight: 700;
          color: rgba(255, 255, 255, 1);
        }

        .line {
          margin: auto;
          width: 125px;
          height: 4px;
          opacity: 1;
          background: rgba(255, 255, 255, 1);
        }
      }

      .morebox {
        text-align: center;
        margin-top: 180px;
        // background-color: black;
        animation: join 1s;

        .name {
          font-size: 30px;
          font-weight: 700;
          color: rgba(42, 130, 228, 1);
        }

        .littlename {
          font-size: 24px;
          // margin: 0 !important;
          // font-weight: 400;
          margin-top: 10px;
          margin-bottom: 0 !important;
          color: rgba(255, 255, 255, 1);
        }
      }

      @keyframes join {
        from {
          transform: translateY(100%);
        }

        to {
          transform: translateY(0%);

        }
      }
    }
  }

  .partner {
    display: flex;
    flex-direction: row;
    height: 700px;
    color: #fff;
    background-color: #fff;

    .main {
      width: 1680px;
      margin: 0 auto;
      // background-color: rgb(203, 214, 106);
    }

    .partner_title {
      margin-top: 171px;
      font-size: 36px;
      font-weight: 400;
      color: rgba(115, 115, 115, 1);
      text-align: center;
    }



    .module_swiper {
      width: 1600px;
      margin: 0 auto;
      position: relative;

      img {
        width: 100px;
      }
    }

    .swiper-button-prev:after {
      display: none;
    }

    .swiper-button-next:after {
      display: none;
    }

    /*再自定义样式*/
    .swiper-button-prev {
      box-shadow: 1PX 1PX 8PX 2PX rgba(0, 0, 0, 0.05);
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background: url('../assets/678647682a948a36785acbce39fefae1.png') no-repeat;
      bottom: 15px;
      background-size: 100% 100%;
      background-position: center center;
      // top: 90%;
      // left: 40%;
    }

    .swiper-button-prev:hover {
      width: 35px;
      height: 35px;
      box-shadow: 0PX 0PX 0PX 0PX rgba(0, 0, 0, 0.05);

      background: url('../assets/5286baf52f2e804163781680e988600.png') no-repeat;
      bottom: 15px;
      background-size: 100% 100%;
      background-position: center center;
      // top: 90%;
      // left: 40%;
    }

    .swiper-button-next {
      box-shadow: 1PX 1PX 8PX 2PX rgba(0, 0, 0, 0.05);
      border-radius: 50%;

      width: 35px;
      height: 35px;
      background: url('../assets/678647682a948a36785acbce39fefae.png') no-repeat;

      // top: 90%;
      // left: 60%;
      background-size: 100% 100%;
      background-position: center center;
    }

    .swiper-button-next:hover {
      width: 35px;
      box-shadow: 0PX 0PX 0PX 0PX rgba(0, 0, 0, 0.05);

      height: 35px;
      background: url('../assets/5286baf52f2e804163781680e988601.png') no-repeat;

      // top: 90%;
      // left: 60%;
      background-size: 100% 100%;
      background-position: center center;
    }

    .theSwiper {
      width: 1422px;
      margin: 0 auto;

      // margin-top: 107px;



      .swiper-container {
        position: relative;
        width: 100%;
        height: 400px;


        .swiper-slide {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          // margin: 0 !important;
          color: rgb(95, 28, 28);
          // background-color: rgba(30, 103, 212, 0.116);
          font-size: 16px;
          text-align: center;
        }

        .imgbox {
          height: 200px;
          width: 60px;
          background-color: blue;
        }
      }

      img {
        // height: 70px;
        width: 200px;
      }
    }
  }



  .footer {
    position: relative;
    height: 300px;
    color: #C4C4C4;
    background-color: #20262d;

    .main {
      width: 1498px;
      margin: 0 auto;
      // background-color: rgb(203, 214, 106);
    }

    .footer_top {
      margin-top: 50px;
      height: 48px;
      vertical-align: middle;
      border-bottom: 0.2px solid rgba(166, 166, 166, 1);
      display: flex;

      .top_left {
        margin-left: 52px;

        .imgbox {
          display: flex;
        }

        img {
          width: 27px;
        }

        .text {
          margin-left: 20px;
          font-size: 18px;
          line-height: 25px;
        }
      }

      .top_right {
        margin-left: auto;
        margin-right: 297px;
        // text-align: right;
      }
    }

    .footer_centre {
      // padding-top: 31px;
      height: 123px;
      border-bottom: 0.2px solid rgba(166, 166, 166, 1);
      display: flex;

      .centre_left {
        margin-top: 31px;
        margin-left: 52px;

        img {
          width: 18px;
          height: 18px;
        }

        .text {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          margin-left: 20px;
        }

        .icon_text {
          // margin-top: 14px;
          display: flex;
        }

        .icon_text1 {
          margin-top: 14px;
          display: flex;
        }
      }

      .centre_centre {
        margin-left: 260px;

        img {
          width: 18px;
          height: 18px;
        }

        .text {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          margin-left: 20px;
        }

        .icon_text {
          margin-top: 54px;
          display: flex;
        }
      }

      .centre_right {
        margin-left: auto;
        // margin-right: 44px;
        margin-top: 21px;

        .right_title {

          font-size: 16px;
          font-weight: 400;


        }

        .callme {

          display: flex;
          margin-top: 17px;

          .icon {
            height: 41px;
            width: 41px;
            border-radius: 41px;
            border: 1px solid rgba(122, 122, 122, 1);
            margin-right: 35px;
            text-align: center;
            vertical-align: middle;
            line-height: 48px;


          }
        }

        .wechat-qrcode {

          box-sizing: border-box;
          margin: 0;
          padding: 0;
          outline: none;
          display: block !important;
          position: absolute;
          z-index: 999;
          right: 402px;
          width: 200px;
          color: #666;
          font-size: 12px;
          text-align: center;
          transition: all 200ms;
          height: 176px;
          top: -26px;
          border: 1px solid #eee;
          background-color: #fff;
          box-shadow: 0 2px 10px #aaa;

          .share-close {
            background: transparent;
            border: 0;
            font-size: 25px;
            position: absolute;
            top: -6px;
            right: 2px;
            color: #000 !important;
          }

          h4 {
            height: 26px;
            line-height: 26px;
            font-size: 12px;
            background-color: #f3f3f3;
            margin: 0;
            padding: 0;
            color: #777;
          }

          .qrcode {
            width: 138px;
            margin: 5px auto;
          }

          img {
            width: 100%;
            vertical-align: middle;
          }
        }

        .wechat-qrcode:after {
          border-width: 8px 6px 6px 6px;
          top: auto;
          border-color: #f3f3f3 transparent transparent transparent;
        }

        .wechat-qrcode:after {
          content: '';
          position: absolute;
          left: 50%;
          margin-left: -6px;
          // top: -13px;
          width: 0;
          height: 0;
          // border-width: 6px 6px 8px 6px;
          border-style: solid;
          // border-color: transparent transparent #f3f3f3 transparent;
        }
      }
    }

    .footer_end {
      .text {
        margin-left: 52px;
        margin-top: 21px;
        font-size: 14px;
        font-weight: 400;

        line-height: 18.47px;
        color: #C4C4C4;
      }



    }

  }

  .header-title {

    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 34.75px;
    color: rgba(56, 56, 56, 1);
    text-align: left;
    vertical-align: top;
  }

  .dialogvideobox {
    width: 100%;
    border-radius: 7px;
  }

  .text_box {
    margin-top: 30px;

    div {
      font-size: 14px;
      margin-top: 30px;
      // margin-bottom: 20px;
    }

    p {
      margin: 0;
      color: rgba(128, 128, 128, 1);
    }
  }

  .littletitle {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    // line-height: 23.17px;
    color: rgba(36, 102, 168, 1);
  }

  .strongspan {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0px;
    // line-height: 20.27px;
    color: rgba(56, 56, 56, 1);
  }

  ::v-deep .el-dialog {
    border-radius: 10px !important;
  }

  ::v-deep .el-dialog__body {
    height: 1050px !important;
    overflow: auto;
  }
}
</style>
