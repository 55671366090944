<template>
    <div class="container">
        <div class="case">
            <div class="main">

                <div class="en_title">
                    Customer Cases
                </div>
                <div class="cn_title">
                    客户案例
                    <h1 v-show="false" @click="gotomore()">浙江数翰科技有限公司</h1>

                </div>
            </div>
        </div>
        <div class="tabheader">
            <div class="main">
                <div v-for="i in tabtitle" class="tab" @click="tabclick(i)">
                    <div class="tabtext">{{ i.name }}</div>
                    <div class="line" v-if="tabindex == i.index"></div>
                </div>
            </div>
        </div>
        <div class="tabcontent" :style="{ backgroundImage: `url(${bgimg})` }" v-if="tabindex !== 2">
            <div class="main">
                <div class="factory_info" :class="showmdtitle ? 'joinleft' : ''" v-show="showmdtitle">
                    <ul>
                        <li>
                            <span>设计水量：</span>
                            <span>{{ tabdata[tabindex].water }}</span>
                        </li>
                        <li>

                            <span>处理工艺：</span>
                            <span>{{ tabdata[tabindex].gongyi }}</span>
                        </li>
                        <li>

                            <span>运行时间：</span>
                            <span>{{ tabdata[tabindex].time }}</span>
                        </li>
                        <li class="standard">
                            <span>排放标准：</span>
                            <div class="out">
                                <div v-for="i in tabdata[tabindex].standard" class="outbox">
                                    {{ i.name }}: {{ i.data }}
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div>
                    </div>
                </div>
                <div class="factory_title" :class="showmdtitle ? 'joinright' : ''" v-show="showmdtitle">
                    {{ tabdata[tabindex].name }}
                </div>
            </div>
        </div>
        <div class="config" v-if="tabindex !== 2">
            <div class="main">

                <div class="config_title">
                    系统配置
                </div>
                <div class="config_list">
                    <div v-for="item in configlist" @mouseenter="hoverconfig(item)" @mouseleave="leaveconfig(item)"
                        :class="configindex == item.index ? 'config_box' : 'hover_box'">
                        <div class="config_text">
                            {{ item.name }}
                            <div class="config_icon" v-if="configindex == item.index">
                                <img src="../../assets/duigou.png" alt="">
                            </div>
                            <div class="config_num" v-else>
                                0{{ item.index + 1 }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="advantage" v-if="tabindex !== 2">
            <div class="main">
                <div class="advantage_title">
                    控制效果展示
                </div>
                <div class="DO_box">
                    <div class="DO_top">
                        <div class="DO_chart" :class="showDOchart ? 'joinleft' : ''">
                            <img :src=advantagelist[0].img alt="">
                        </div>
                        <div class="DO_lengd" :class="showDOchart ? 'joinright' : ''">
                            <div class="chartname">
                                {{ advantagelist[0].title }}
                            </div>
                            <div class="color_one">
                                <div class="color_box">

                                    <div class="color" :style="{ backgroundColor: advantagelist[0].color1 }">
                                    </div>
                                </div>
                                <div class="text">
                                    {{ advantagelist[0].text1 }}
                                </div>
                            </div>
                            <div class="color_two">
                                <div class="color_box">

                                    <div class="color" :style="{ backgroundColor: advantagelist[0].color2 }">
                                    </div>
                                </div>
                                <div class="text">
                                    {{ advantagelist[0].text2 }}
                                </div>
                            </div>
                            <div class="color_three" v-if="advantagelist[0].color3">
                                <div class="color_box">

                                    <div class="color" :style="{ backgroundColor: advantagelist[0].color3 }">
                                    </div>
                                </div>
                                <div class="text">
                                    {{ advantagelist[0].text3 }}
                                </div>
                            </div>
                            <div class="top_line" v-if="advantagelist[0].topline">
                                <div class="color_box">

                                    <div class="color">
                                    </div>
                                </div>
                                <div class="text">
                                    合理控制区间
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="DO_bottom" :class="showDOchart ? 'goup' : ''">
                        {{ advantagelist[0].content }}
                    </div>
                </div>
                <div class="TN_box" v-if="advantagelist.length > 1">
                    <div class="TN_top">
                        <div class="TN_lengd" :class="showTNchart ? 'joinleft' : ''">
                            <div class="chartname">
                                {{ advantagelist[1].title }}
                            </div>
                            <div class="color_one">
                                <div class="color" :style="{ backgroundColor: advantagelist[1].color1 }">

                                </div>
                                <div class="text">
                                    {{ advantagelist[1].text1 }}
                                </div>
                            </div>
                            <div class="color_two">
                                <div class="color" :style="{ backgroundColor: advantagelist[1].color2 }">
                                </div>
                                <div class="text">
                                    {{ advantagelist[1].text2 }}
                                </div>
                            </div>
                            <div class="top_line" v-if="advantagelist[1].topline">
                                <div class="color_box">

                                    <div class="color">
                                    </div>
                                </div>
                                <div class="text">
                                    合理控制区间
                                </div>
                            </div>
                        </div>
                        <div class="TN_chart" :class="showTNchart ? 'joinright' : ''">
                            <img :src=advantagelist[1].img alt="">
                        </div>
                    </div>
                    <div class="TN_bottom">
                        {{ advantagelist[1].content }}
                    </div>
                    <div class="TN_bottom2">
                        {{ advantagelist[1].content1 }}
                    </div>

                </div>
            </div>
        </div>
        <!-- <div class="moreCase" v-if="tabindex == 2"> -->
        <div class="imgbox" v-for="item in morecaselist" v-if="tabindex == 2">
            <div class="main">
                <div class="factory_info">
                    <ul>
                        <li>
                            <span class="outtitle">案例名称：</span>
                            <span>{{ item.name }}</span>
                        </li>
                        <li>
                            <span class="outtitle">设计水量：</span>
                            <span>{{ item.water }}</span>
                        </li>
                        <li class="standard">
                            <span class="outtitle">处理工艺：</span>
                            <div>
                                <div v-for="i in item.gongyi" class="outbox">
                                    {{ i }}
                                </div>
                            </div>
                        </li>
                        <li v-if="item.time">
                            <span class="outtitle">运行时间：</span>
                            <span>{{ item.time }}</span>
                        </li>
                        <li class="standard">
                            <span class="outtitle">排放标准：</span>
                            <div class="out">
                                <div v-for="i in item.standard" class="outbox">
                                    {{ i }}
                                </div>
                            </div>
                        </li>
                        <li class="standard">
                            <span class="outtitle">系统配置：</span>
                            <div class="out">
                                <div v-for="i in item.system" class="outbox">
                                    <span class="systemitem">
                                        {{ i }}
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div>
                    </div>
                </div>
                <div class="factory_title">
                    <img :src="item.bgimg" alt="">
                </div>
            </div>
        </div>

        <div class="footer">
            <myfoooter></myfoooter>
        </div>

    </div>

</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import myfoooter from "../../components/footer.vue";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        myfoooter,
        swiper,
        swiperSlide,
    },
    data() {
        return {
            tabtitle: [
                {
                    index: 0,
                    name: '市政污水处理厂',
                    url: require("../../assets/shizheng.png"),

                },
                {
                    index: 1,
                    name: '工业污水处理厂',
                    url: require("../../assets/yudai.png"),
                },
                {
                    index: 2,
                    name: '更多',
                    url: require("../../assets/yudai.png"),
                }
            ],
            tabindex: 0,
            bgimg: '',
            configlist: [
                {
                    index: 0,
                    name: '智能生化系统',
                },
                {
                    index: 1,
                    name: '智能加药系统（PAC/PAM）',

                },
                {
                    index: 2,
                    name: '智能臭氧投加系统',

                },
                {
                    index: 3,
                    name: '智能消毒系统',
                }
            ],
            allconfiglist: [
                [

                    {
                        index: 0,
                        name: '智能生化系统',
                    },
                    {
                        index: 1,
                        name: '智能流控系统',

                    },
                    {
                        index: 2,
                        name: '智能臭氧投加系统',

                    },
                    {
                        index: 3,
                        name: '智能加药系统',
                    }
                ],
                [
                    {
                        index: 0,
                        name: '智能生化系统（碳源投加）',
                    },
                    {
                        index: 1,
                        name: '智能臭氧投加系统',

                    },
                    {
                        index: 2,
                        name: '智能加药系统(PAC\PAM)',
                    },

                ]
            ],
            configindex: 0,
            tabdata: [
                {
                    name: '某日处理20万吨市政污水处理厂',
                    water: '20万吨/日',
                    gongyi: '多级AO+高效+臭氧氧化+后置MBBR',
                    time: '2021年10月-至今',
                    standard: [
                        {
                            name: 'COD',
                            data: '30mg/L ',
                        }, {
                            name: 'NH₃-N',

                            data: '1.5(3)mg/L',
                        }, {

                            name: 'TN',

                            data: '10(12)mg/ L',
                        }, {
                            name: 'TP',
                            data: '0.3mg / L',
                        }
                    ]
                },
                {
                    name: '某日处理1.25万吨工业污水处理厂',
                    water: '1.25万吨/日',
                    gongyi: 'AO+高效+臭氧氧化+后置MBBR',
                    time: '2022年9月-至今',
                    standard: [
                        {
                            name: 'COD',
                            data: '50mg/L ',
                        }, {
                            name: 'NH₃-N',

                            data: '5(8)mg/L',
                        }, {

                            name: 'TN',

                            data: '15mg/ L',
                        }, {
                            name: 'TP',
                            data: '0.5mg / L',
                        }
                    ]
                }
            ],
            advantagelist: [
                {
                    title: '(例)智能生化系统-DO控制图',
                    text1: 'DO实际控制值',
                    text2: 'DO目标控制值',
                    img: require("../../assets/DO2x.png"),
                    content: "浮动DO的目标值为AI大脑根据来水负荷实时的计算结果。当系统得到浮动DO目标值之后，通过自动调控硬件(风机、阀门)，达到DO的精准控制。相比较人工给定系统目标值的“恒定DO”，浮动DO更能灵活、及时的应对来水负荷变化、组分变化。"
                },
                {
                    title: '(例)智能生化系统-TN控制图',
                    text1: 'TN实际控制值',
                    text2: 'TN目标控制值',
                    img: require("../../assets/TN2x.png"),
                    content: "智能生化系统是将智能曝气系统、智能碳源投加系统联动，通过负荷感知和负荷分摊技术，从整体最优的策略角度考量生化系统所需的风量和药耗。",
                    content1: "如上图所示，在TN内控线为8mg/L时，同时对比AI和人工运行2条线，在AI运行下TN不仅牢牢控在线内，同时也较人工控制更为稳定平滑。"
                }
            ],
            alladvantagelist: [
            ],
            yudailist: [
                [
                    {
                        title: '(例)智能生化系统(碳源投加)-TN控制图',
                        text1: '出水TN-AI控制趋势',
                        text2: '出水TN-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/分组 6@2x (1).png"),
                        content: "如图所示，此工艺段总氮的排放标准为15mg/L以下，目标控制值为11mg/L，在智能生化系统(碳源投加)的控制下，总氮可稳定控制在9.5-12.5mg/L，充分保障出水安全的同时也减少了药剂的投入，能够有效的提升安全性和经济性。    "
                    }

                ],
                [
                    {
                        title: '(例)智能臭氧投加系统-臭氧控制图',
                        text1: '臭氧生产量-AI控制趋势',
                        text2: '实时进水COD',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(159, 69, 0, 1)',
                        img: require("../../assets/分组 12@2x (2).png"),
                        content: "如图所示，为臭氧工艺段进水的COD曲线和臭氧投加曲线，臭氧投加量根据进水变化实时调整；智能臭氧投加系统根据实时进水COD数据自动调整臭氧投加量，大大提高了臭氧接触池的处理效率。"
                    },
                    {
                        title: '(例)智能臭氧投加系统-COD控制图',
                        text1: '出水COD-AI控制趋势',
                        text2: '出水COD-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/分组 2@2x (4).png"),
                        content: "如图所示，此工艺段的出水COD目标控制值为40mg/L，在智能臭氧投加系统的控制下出水COD可以稳定控制在目标控制值加减2mg/L以内。在确保出水水质达标的情况下，使出水指标稳定在目标值附近。与人工控制相比，AI控制更加稳定可靠，提高运行安全性和经济性。"
                    },
                ],
                [
                    {
                        title: '(例)智能加药系统(PAC\PAM) -TP控制图',
                        text1: '出水TP-AI控制趋势',
                        text2: '出水TP-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/分组 11@2x.png"),
                        content: "如图所示，此工艺段的总磷排放标准为0.5mg/L，总磷目标控制值为0.18mg/L，在智能加药系统(PAC\PAM)的控制下，出水总氮可稳定控制在0.15-0.21mg/L，充分保障出水安全，减少药剂投入，有效的提升安全性和经济性。"
                    },
                ]
            ],
            wtlist: [
                [
                    {
                        title: '(例)智能生化系统-DO控制图',
                        text1: 'DO-AI控制趋势',
                        text2: 'DO-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/DO2x.png"),
                        content: "浮动DO的目标值为AI大脑根据来水负荷实时的计算结果。当系统得到浮动DO目标值之后，通过自动调控硬件(风机、阀门)，达到DO的精准控制。相比较人工给定系统目标值的“恒定DO”，浮动DO更能灵活、及时的应对来水负荷变化、组分变化。"
                    },
                    {
                        title: '(例)智能生化系统-TN控制图',
                        text1: '出水TN-AI控制趋势',
                        text2: '出水TN-人工控制趋势',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(11, 102, 100, 1)',
                        img: require("../../assets/TN2x.png"),
                        content: "智能生化系统是将智能曝气系统、智能碳源投加系统联动，通过负荷感知和负荷分摊技术，从整体最优的策略角度考量生化系统所需的风量和药耗。",
                        content1: "如上图所示，在TN内控线为8mg/L时，同时对比AI和人工运行两条线，在AI运行下TN不仅牢牢控在线内，同时也较人工控制更为稳定平滑。"
                    }
                ],
                [
                    {
                        title: '(例)智能加药系统(PAC/PAM)-TP控制图',
                        text1: '出水TP-AI控制趋势',
                        text2: '出水TP-人工控制趋势',
                        text3: '出水TP-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(11, 102, 100, 1)',
                        color3: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/pactp.png"),
                        content: "如图所示，此工艺段出水TP的目标控制值为0.1mg/L，在智能加药系统(PAC/PAM)的控制下出水TP能够更好的稳定在目标控制值加减0.03的范围内，相对人工控制来说,有效的提升了出水安全性，能有效减少安全缓冲空间，提高经济性。"
                    },
                ],
                [
                    {
                        title: '(例)智能臭氧投加系统-COD控制图',
                        text1: '出水COD-AI控制趋势',
                        text2: '出水COD-人工控制趋势',
                        text3: '出水COD-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(11, 102, 100, 1)',
                        color3: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/o3cod.png"),
                        content: "如图所示，此工艺段出水COD的目标值为20mg/L，在智能臭氧投加系统的控制下出水COD能够更好的稳定在目标控制值加减2的范围以内，相比人工控制能够更好保证出水水质达标，能有效减少安全缓冲空间，从而提高经济性。"
                    },
                ],
                [
                    {
                        title: '(例)智能消毒系统-ORP控制图',
                        text1: '出水ORP-AI控制趋势',
                        text2: '出水ORP-人工控制趋势',
                        text3: '出水ORP-目标控制值',
                        color1: 'rgba(38, 87, 143, 1)',
                        color2: 'rgba(11, 102, 100, 1)',
                        color3: 'rgba(238, 136, 45, 1)',
                        topline: true,
                        img: require("../../assets/orp.png"),
                        content: "如图所示，在智能消毒系统的控制下，能够将此工艺段出水ORP更好的稳定在目标控制值(350mg/L)加减50mg/L的范围以内，相对人工控制来说,有效的提升了出水稳定性，更稳定的消除污水中的细菌微生物，同时可以减少药剂的过多投加，提升了经济性。"
                    },
                ],
            ],
            showmdtitle: false,
            showDOchart: false,
            showTNchart: false,
            morecaselist: [
                {
                    name: '某日处理7.98万吨化工园区污水厂',
                    water: '7.98万吨/天',
                    gongyi: [
                        '【高浓线】厌氧GSB + AO + MBBR + 高效沉淀 + 臭氧 + 磁混凝',
                        '【低浓线】A2O + MBBR + 高效沉淀 + 臭氧 + 磁混凝',
                        '【清下水】硫自养反硝化滤池 + BAF + 臭氧 + 磁混凝'
                    ],
                    time: '2023年6月-至今',
                    standard: ['《城镇污水处理厂污染物排放标准》(GB18918-2002)一级A标准'],
                    system: [
                        "微澜配水系统", " 微澜曝气系统", "微澜碳源投加系统",
                        "微澜流控系统", "微澜混凝投加系统", "微澜污泥回流控制系统",
                        "微澜臭氧投加系统", " 微澜粉炭投加系统", "微澜消毒投加系统"
                    ],
                    bgimg: require("../../assets/图片1.png"),
                },
                {
                    name: '某日处理3.75万吨化工园区污水厂',
                    water: '3.75万吨/天',
                    gongyi: [
                        '臭氧+AO+高效沉淀+臭氧+MBBR+臭氧活性炭滤床'
                    ],
                    time: '2023年5月-至今',
                    standard: ["达标尾水RO系统（COD：50mg/L、NH₃-N：5mg/L）",
                        "循环水RO系统（COD：30mg/L、NH₃-N：5mg/L）"],
                    system: [
                        "微澜臭氧投加系统"
                    ],
                    bgimg: require("../../assets/图片2.png"),
                },
                {
                    name: '某日处理5万吨化工园区污水厂',
                    water: '5万吨/天',
                    gongyi: [
                        '水解酸化+A2O+磁混凝+BAF+臭氧',
                    ],
                    time: '',
                    standard: ['《城镇污水处理厂污染物排放标准》（GB 18918-2002）一级A标准'],
                    system: [
                        " 微澜曝气系统", "微澜碳源投加系统", "微澜混凝投加系统"
                    ],
                    bgimg: require("../../assets/图片3.png"),
                },
                {
                    name: '某日处理10万吨综合污水厂',
                    water: '10万吨/天',
                    gongyi: [
                        'A2O+高效沉淀+反硝化滤池'
                    ],
                    time: '2024年3月-至今',
                    standard: ['COD：35mg/L、TN：10mg/L、NH₃-N：1mg/L、TP：0.3mg/L'],
                    system: [
                        "微澜配水系统", " 微澜曝气系统", "微澜碳源投加系统",
                        "微澜混凝投加系统", "微澜消毒投加系统", "微澜污泥回流控制系统"
                    ],
                    bgimg: require("../../assets/图片4.png"),
                },
                {
                    name: '某日处理6万吨市政污水厂',
                    water: '6万吨/天',
                    gongyi: [
                        'A2O+磁混凝+BAF',
                    ],
                    time: '2023年10月-至今',
                    standard: ['《太湖地区城镇污水处理厂及重点工业行业主要水污染物排放限值》(DB32/1072 —2018) 及城镇污水处理厂污染物排放标准》（GB18918-2002）一级A标准'],
                    system: [
                        "微澜碳源投加系统",
                        "微澜混凝投加系统",
                    ],
                    bgimg: require("../../assets/图片5.png"),
                },
                {
                    name: '某日处理2.45万吨石化企业污水厂',
                    water: '2.45万吨/天',
                    gongyi: [
                        '【高盐】AO(MBBR)+高效沉淀+臭氧+MBBR',
                        '【低盐】AO(MBBR)+高效沉淀',
                        '【中水】臭氧+MBBR+UF/RO'
                    ],
                    time: '2024年4月-至今',
                    standard: ['《城镇污水处理厂污染物排放标准》（GB18918-2002）一级A标准'],
                    system: [
                        "微澜pH调节系统", "微澜曝气系统", "微澜流控系统 ",
                        "微澜混凝投加系统 ", "微澜污泥回流控制系统 ", "微澜除硬投加系统",
                        "微澜臭氧投加系统",

                    ],
                    bgimg: require("../../assets/图片6.png"),
                },
                {
                    name: '某日处理1.44万吨石化企业污水厂',
                    water: '1.44万吨/天',
                    gongyi: [
                        '【污水】厌氧+两级好氧',
                        "【中水】UF+RO"
                    ],
                    time: '',
                    standard: ['COD：500mg/L、SS：70mg/L、NH₃-N：5mg/L'],
                    system: [
                        "微澜pH调节系统",
                        "微澜消毒投加系统",
                    ],
                    bgimg: require("../../assets/图片7.png"),
                },
            ]
        };
    },
    created() {
        this.bgimg = this.tabtitle[0].url
        console.log(this.bgimg);
        this.alladvantagelist = this.wtlist
        this.advantagelist = this.alladvantagelist[0]
        window.addEventListener("scroll", this.handleScroll);
    },
    // mounted() { },

    methods: {
        handleScroll() {
            let scrollall = document.documentElement.clientHeight || document.body.offsetHeight

            let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop);
            if (scrollTop > scrollall * 0.01) {
                this.showmdtitle = true
            }
            if (scrollTop > scrollall * 0.69) {
                this.showDOchart = true
            }
            if (scrollTop > scrollall * 1.15) {
                this.showTNchart = true
            }
        },
        hoverconfig(item) {
            console.log(item);
            // this.showDOchart = true
            // this.showDOchart = false
            this.configindex = item.index
            this.advantagelist = this.alladvantagelist[item.index]
            console.log(this.advantagelist);
        },
        leaveconfig(item) {
            // this.showDOchart = false

        },
        tabclick(i) {
            this.tabindex = i.index
            this.bgimg = this.tabtitle[i.index].url
            this.configlist = this.allconfiglist[i.index]
            return
            if (i.index == 0) {
                this.alladvantagelist = this.wtlist
                this.advantagelist = this.alladvantagelist[0]
            } else {
                this.alladvantagelist = this.yudailist
                this.advantagelist = this.alladvantagelist[0]

            }
        },
        gotomore() {
            this.$router.push('/service');
        }
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

@keyframes titleShow {
    from {
        transform: translateY(1000%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes leftShow {
    from {
        transform: translatex(-100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes rightShow {
    from {
        transform: translatex(100%);
    }

    to {
        transform: translateX(0px);
    }
}

.goup {
    animation: titleShow 1s;

}

.joinleft {
    animation: leftShow 1s;
}

.joinright {
    animation: rightShow 1s;
}

.container {
    display: grid;
    overflow: auto;
    height: 100%;
    // width: 1920px;
    margin: 0 auto;
    overflow: visible;

    .main {

        width: 1522px;
        margin: 0 auto;

    }

    .case {
        height: 791px;
        // background-color: #487fbe;
        background-image: url("../../assets/casebg.png"); //背景图
        background-size: 100% 100%;
        background-position: center center;

        .en_title {
            margin-top: 363px;
            // margin-left: 201px;
            font-size: 60px;
            font-weight: 300;
            line-height: 43px;
            color: rgba(255, 255, 255, 1);
        }

        .cn_title {
            margin-top: 32px;
            // margin-left: 201px;
            font-size: 48px;
            color: rgba(255, 255, 255, 1);

        }
    }

    .tabheader {
        height: 140px;
        background-color: rgba(255, 255, 255, 1);

        .main {
            display: flex;

            .tab {
                margin-top: 49px;
                font-size: 24px;
                font-weight: 400;
                color: rgba(99, 99, 99, 1);
                // width: 168px;
                margin-right: 83px;

                .tabtext {
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 43px;
                    color: rgba(99, 99, 99, 1);
                }
            }

            .line {
                margin: auto;
                margin-top: 5px;
                width: 100%;
                height: 4px;
                opacity: 1;
                background: rgba(56, 138, 232, 1);
            }
        }
    }

    li {
        list-style: none;
        font-size: 20px;
        font-weight: 400;
        line-height: 37px;
        color: rgba(217, 217, 217, 1);

    }

    .tabcontent {
        height: 812px;
        position: relative;
        background-size: 100% 100%;
        background-position: center center;

        .factory_info {
            margin-top: 277px;
            // margin-left: 263px;
            width: 503px;
            height: 259px;
            // background-color: #fff;

            ul {
                padding: 0;
            }

            li {
                list-style: none;
                font-size: 20px;
                font-weight: 400;
                line-height: 37px;
                color: rgba(217, 217, 217, 1);

            }

            .standard {
                display: flex;

                .out {
                    width: 400px;
                    // background-color: #fff;
                    display: flex;

                    flex-wrap: wrap;

                    .outbox {
                        width: 200px;
                    }
                }
            }
        }

        .factory_title {
            margin-top: 76px;
            text-align: right;
            font-size: 48px;
            font-weight: 400;
            color: rgba(230, 230, 230, 1)
        }

    }

    .wrap_mask:after {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        background-color: rgb(0, 0, 0);
        // background-color: linear-gradient(91.54deg, rgba(19, 35, 54, 1) 0%, rgba(27, 31, 38, 0) 100%);
        opacity: 0.2;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .config {
        height: 394px;
        background-color: #fff;

        .config_title {
            margin-top: 100px;
            font-size: 36px;
            font-weight: 400;
            color: rgba(115, 115, 115, 1);

        }

        .config_list {
            margin-top: 49px;
            display: flex;
            justify-content: center;
            align-items: center;

            .hover_box {
                margin-right: 54px;
                width: 80%;
                height: 90px;
                border-radius: 6px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                text-align: center;

                .config_text {

                    color: rgba(102, 102, 102, 1);
                }
            }

            .config_box {
                margin-right: 54px;
                width: 80%;

                height: 90px;
                border-radius: 6px;
                background: rgba(56, 138, 232, 1);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
                text-align: center;

                // line-height: 90px;
                .config_text {

                    color: rgba(255, 255, 255, 1);
                }
            }

            .config_text {
                position: relative;
                margin-top: 24px;
                font-size: 24px;
                font-weight: 400;

                line-height: 43px;
                color: rgba(255, 255, 255, 1);

                .config_icon {
                    position: absolute;
                    top: 20px;
                    right: 20px;

                    img {
                        width: 29px;
                    }
                }

                .config_num {
                    position: absolute;
                    top: -45px;
                    right: 8px;

                    width: 58px;
                    height: 43px;

                    font-size: 48px;
                    font-weight: 400;

                    color: rgba(207, 207, 207, 1);


                }

            }

            .config_box:last-child {
                // margin-right: 0px;
            }
        }
    }

    .advantage {
        // height: 1781px;

        background: linear-gradient(139.58deg, rgba(2, 22, 44, 1) 0%, rgba(90, 92, 94, 1) 100%);

        .advantage_title {
            margin-top: 122px;
            font-size: 36px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
        }

        .DO_box {
            margin-top: 123px;
            margin-bottom: 123px;

            .DO_top {
                display: flex;

                .DO_chart {
                    width: 936px;
                    height: 430px;
                    opacity: 1;

                    img {
                        width: 100%;
                    }
                }

                .DO_lengd {
                    margin-top: 142px;
                    margin-left: 132px;

                    .chartname {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 26px;
                        color: rgba(242, 242, 242, 1);
                    }

                    .color_box {
                        width: 32px;
                        margin-right: 21px;
                        // text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                    }

                    .color_one {
                        margin-top: 20px;
                        display: flex;


                        // .color {
                        //     background: rgba(38, 87, 143, 1);
                        // }
                    }

                    .color_two {
                        margin-top: 20px;
                        display: flex;

                        // .color {
                        //     background: rgba(238, 136, 45, 1);
                        // }
                    }

                    .color_three {
                        margin-top: 20px;
                        display: flex;

                        // .color {
                        //     background: rgba(238, 136, 45, 1);
                        // }
                    }

                    .top_line {
                        margin-top: 20px;
                        display: flex;

                        .color {
                            // margin: 0;
                            // position: absolute;
                            height: 3px;
                            width: 32px;
                            background-image: url("../../assets/pointline.png"); //背景图
                            background-size: 100% 100%;
                            background-position: center center;
                            // background: rgba(238, 136, 45, 1);
                        }
                    }

                    .color {
                        // margin-right: 21px;
                        width: 18px;
                        height: 18px;
                        border-radius: 2px;
                        margin-top: 4px;
                    }

                    .text {
                        line-height: 26px;
                        font-size: 18px;
                        font-weight: 400;
                        color: rgba(217, 217, 217, 1);
                    }
                }
            }

            .DO_bottom {
                margin-left: 173px;
                margin-top: 49px;

                width: 748px;
                height: 104px;

                font-size: 18px;
                font-weight: 400;

                line-height: 26px;
                color: rgba(209, 209, 209, 1);

            }
        }

        .TN_box {
            margin-bottom: 123px;
            // margin-top: 123px;

            .TN_top {
                display: flex;

                .TN_chart {
                    margin-left: 157px;
                    width: 936px;
                    height: 430px;
                    opacity: 1;

                    img {
                        width: 100%;
                    }
                }

                .TN_lengd {
                    margin-top: 142px;
                    // margin-left: 132px;

                    .chartname {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 26px;
                        color: rgba(242, 242, 242, 1);
                    }

                    .color_one {
                        margin-top: 20px;
                        display: flex;


                        // .color {
                        //     background: rgba(38, 87, 143, 1);
                        // }
                    }

                    .color_two {
                        margin-top: 20px;
                        display: flex;

                        // .color {
                        //     background: rgba(212, 48, 48, 1);
                        // }
                    }

                    .color {
                        margin-right: 21px;
                        width: 18px;
                        height: 18px;
                        border-radius: 2px;
                        margin-top: 4px;
                    }

                    .text {
                        line-height: 26px;
                        font-size: 18px;
                        font-weight: 400;
                        color: rgba(217, 217, 217, 1);
                    }

                    .top_line {
                        margin-top: 20px;
                        display: flex;

                        .color {
                            // margin: 0;
                            // position: absolute;
                            height: 3px;
                            width: 32px;
                            background-image: url("../../assets/pointline.png"); //背景图
                            background-size: 100% 100%;
                            background-position: center center;
                            // background: rgba(238, 136, 45, 1);
                        }
                    }

                }
            }

            .TN_bottom {
                margin-left: 456px;
                margin-top: 49px;

                width: 748px;
                height: 104px;

                font-size: 18px;
                font-weight: 400;

                line-height: 26px;
                color: rgba(209, 209, 209, 1);

            }

            .TN_bottom2 {
                margin-left: 456px;


                width: 748px;
                height: 104px;

                font-size: 18px;
                font-weight: 400;

                line-height: 26px;
                color: rgba(209, 209, 209, 1);
            }
        }
    }

    // .moreCase {
    .imgbox {

        height: 500px;
        position: relative;
        background: linear-gradient(90deg, rgb(2, 22, 44) 0%, rgb(90, 92, 94) 100%);

        // background-size: 100% 100%;
        // backgro
        .main {
            display: flex;
        }

        .factory_info {
            flex: 1;
            margin-top: 50px;

            .outtitle {
                width: 100px;
            }
        }

        .factory_title {
            flex: 1;
            margin-top: 50px;
            width: 650px;

            img {
                width: 100%;
            }
        }



        .standard {
            display: flex;

            .outtitle {
                // flex: 1;
                width: 100px;
            }

            .out {
                display: flex;
                width: 500px;
                flex-wrap: wrap;

                .systemitem {
                    margin-right: 20px;
                }

                .outbox {
                    // width: 200px;
                }
            }
        }
    }

    // }

    .footer {
        position: relative;
        height: 300px;
        color: #C4C4C4;
        background-color: #20262d;
    }
}
</style>
