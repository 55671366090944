<template>
    <div class="container">
        <div class="core">
            <div class="main">
                <div class="en_title">
                    Core Products
                </div>
                <div class="cn_title">
                    核心产品
                    <h1 v-show="false" @click="gotomore()">浙江数翰科技有限公司</h1>

                </div>
            </div>
        </div>
        <div class="detail">
            <div class="main">

                <div class="detail_left">
                    <div class="en_title">
                        AI Control System
                    </div>
                    <div class="cn_title">
                        AI决策控制系统
                    </div>
                </div>
                <div class="detail_right">
                    <div class="textbox">
                        以自主开发的通用算法大模型为核心，在不同
                        的场景中喂养不同的数据，通过不断的学习、
                        调参可以进化为适应特定场景、特定工艺的AI
                        决策控制系统。

                    </div>
                    <div class="textbox">
                        以水厂为例，结合水厂主流工艺，数翰目前已
                        开发并拥有多个使用案例的AI决策控制系统，
                        这些系统以智控的方式融入水厂运营中，包
                        括：微澜生化系统、微澜流控系统、微澜加药
                        系统、微澜臭氧投加系统、微澜中水处理系
                        统、微澜消毒投加系统、微澜配水系统。
                    </div>
                    <div class="textbox">
                        在水厂智慧运营时，AI大脑还能统筹全流程，
                        打通各智能单元的安全边界，将部分参数控制
                        至看似“不合理”值，而使全厂控制更合理，
                        为全场运营提供安全和经济双优的智慧策略，
                        实现真正意义上的数智化运营。
                    </div>

                </div>
            </div>
        </div>
        <div class="moduleui">
            <div class="md_top">
                <div class="main" v-show="showmdtitle">
                    <div class="ui_title" :class="showmdtitle ? 'goup' : ''">AI决策控制系统-界面展示</div>
                    <div class="ui_img1" :class="showmdtitle ? 'joinleft' : ''">
                        <img src="../../assets/goodui.png" alt="">
                    </div>
                    <div class="ui_img2">
                        <div class="goos_list" :class="showmdright ? 'joinright' : ''">
                            <ul>
                                <li v-for="item in goodslist">
                                    {{
                                        item.text }}
                                </li>
                            </ul>
                        </div>
                        <div class="imgbox" :class="showmdright ? 'joinright' : ''">
                            <img src="../../assets/goodui2.png" alt="">
                        </div>
                    </div>
                    <div>
                        <div style="height: 200px;" v-show="!showmdtop"></div>
                        <div class="module_title" :class="showmdtop ? 'goup' : ''">AI决策控制系统-子模块</div>
                        <div class="module_img" :class="showmdtop ? 'goup' : ''">
                            <img src="../../assets/moduleimg (2).png" alt="">
                        </div>
                    </div>
                </div>
                <div style="height: 200px;" v-show="!showswiper"></div>
                <div class="module_swiper" id="swiper-top" :class="showswiper ? 'goup1' : ''">
                    <div class="theSwiper" ref="swiper">
                        <swiper :options="swiperOption">
                            <swiper-slide v-for="(item, index) in modulelist" :key="item.index">
                                <div class="card" @mouseenter="hovercard(item)" @mouseleave="leavecard(item)"
                                    :class="cardindex == item.index ? 'hoverstyle' : ''">
                                    <div class="neirong">
                                        <div class="titlebox">
                                            <div class="title" :class="cardindex == item.index ? 'hovertitle' : ''">
                                                <div class="tieiletext">
                                                    {{ item.name }}
                                                </div>

                                                <div class="switch">
                                                    <el-switch v-model="item.runbt">
                                                    </el-switch>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="text" :class="cardindex == item.index ? 'hovertext' : ''">
                                            {{ item.text }}
                                        </div>
                                        <img :src="item.url" alt="">
                                    </div>
                                </div>
                            </swiper-slide>
                            <!-- <div class="swiper-navigation" slot="navigation"></div> -->
                            <div slot="button-prev" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-prev'
                                "></div>
                            <div slot="button-next" :class="eqlength <= 4 ? 'swiperStyle' : 'swiper-button-next'
                                "></div>
                        </swiper>
                    </div>
                </div>
                <div class="bgshuhan">
                    <img src="../../assets/SHUHAN.png" alt="">
                </div>
            </div>

        </div>
        <div class="advantage">
            <div class="main">

                <div class="advantage_title" :class="showadvantage ? 'fade' : ''">
                    系统优势
                </div>
                <div class="advantage_list" :class="showadvantage ? 'fade' : ''">
                    <div class="border_box" v-for="item in advantagelist">
                        <div class="icon">
                            <img :src="item.url" alt="">
                        </div>
                        <div class="name">{{ item.name }}</div>
                        <div class="text">{{ item.text }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <myfoooter></myfoooter>
        </div>

    </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import myfoooter from "../../components/footer.vue";
import "swiper/dist/css/swiper.css";
export default {
    components: {
        myfoooter,
        swiper,
        swiperSlide,
    },
    data() {
        return {
            goodslist: [
                {
                    index: 0,
                    text: '01. 一键开启AI智能控制，掌控全厂'
                },
                {
                    index: 1,
                    text: '02. AI智能调控设备， 动频高、动幅小'
                },
                {
                    index: 2,
                    text: '03. 各工艺段重要数据实时展示'
                },
                {
                    index: 3,
                    text: '04. 进出水指标实时监测'
                },
                {
                    index: 4,
                    text: '05. AI智能告警强提醒，保障安全性'
                }
            ],
            advantagelist: [
                {
                    index: 0,
                    name: '安全',
                    url: require("../../assets/anquan2x.png"),
                    text: '保证出水安全，水厂稳定运行'
                },
                {
                    index: 1,
                    name: '精准',
                    url: require("../../assets/jingzhun2x.png"),
                    text: '实时调控设备，达到精准控制'
                }
                , {
                    index: 2,
                    name: '经济',
                    url: require("../../assets/jingji2x.png"),
                    text: '精准控制，节省药耗电耗'
                }
            ],
            modulelist: [
                {
                    index: 0,
                    runbt: false,
                    name: '微澜生化系统',
                    url: require("../../assets/tabbaoqi.png"),
                    text: '通过传感器的在线实时数据，可以感知生化池内负荷；以进出水的 COD、氮等参数作为依据，结合污泥回流系统、微生物状态等多维度指标，将曝气与碳源投加联动，实时计算所需的最优风量和碳源量；进而实时调控风机、风阀以及碳源投加系统，实现精准投加、精确控制、智慧运营。'

                },
                {
                    index: 1,
                    runbt: false,
                    url: require("../../assets/newpeng.png"),
                    name: '微澜加药系统',
                    text: '以目标污染物的出水控制值为中心，利用仿真模拟系统根据实时的来水信息进行预测，以确保实时计算、及时调控，实现精准投加。该系统适用于各类加药功能场景，如高密澄清池、除磷沉淀池、除硬沉淀池等。'
                },
                {
                    index: 2,
                    runbt: false,
                    url: require("../../assets/tabo3.png"),
                    name: '微澜臭氧投加系统',
                    text: '臭氧系统通常以去除 COD 为目标，多用于深度处理工艺。根据现场来水情况和 COD 控制目标，利用仿真系统实时模拟预测，及时给出智慧决策、进而精准调控臭氧发生器的频率和氧气投加。该系统可以与智能生化系统实现最佳联动机制，找到安全与经济双优的最佳策略。'
                },

                {
                    url: require("../../assets/tabxiaodu.png"),
                    index: 3,
                    runbt: false,
                    name: '微澜流控系统',
                    text: "该系统主要应用于使用了流动生物载体的工艺，如 MBBR。流动生物载体类的工艺通常较活性污泥工艺拥有更多更优质的微生物，生化处理效率更高，但同时也带了载体流化状态控制难、溶解氧过高等问题。基于此，在曝气算法基础上，借助图像识别技术感知载体的流态，以更少的风量同时解决了载体堆积和 DO 过高的问题，系统更节能，微生物更健康、更高效。"
                },
                {
                    index: 4,
                    url: require("../../assets/tabso.png"),
                    runbt: false,
                    name: '微澜中水处理系统',
                    text: "我司智能中水处理系统集预警、膜组件调配、泵组控制、智能清洗于一体，适应超滤、反渗透等场景，多维度实时数据监控膜组件健康度，提前预测运行风险，智能选择并启用合理的膜组件，保障膜组污染程度的一致性，提升运行安全性，并延长膜组件使用寿命。"
                },

            ],
            eqlength: 6,
            swiperOption: {
                // loop: true, //首尾循环
                // autoplay: {
                //   delay: 600,
                //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
                //   disableOnInteraction: false, //true：交互后，不继续滚动
                // },
                slidesPerView: 3, //页面中的板块数量
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                freeMode: false, //true:拖到哪就是哪
                spaceBetween: 75, //板块间隔
                grabCursor: false, //true：鼠标为抓手
                // centeredSlides: true,
                slidesOffsetBefore: 60,
                // touchStartPreventDefault: false,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //true：点击底部小圆点可以跳转页面
                    type: "fraction", //当前页显示 1/7
                },
                prevButton: "#swiper-top .swiper-button-prev",
                nextButton: "#swiper-top .swiper-button-next",
                // 设置点击箭头
                navigation: {
                    nextEl: "#swiper-top .swiper-button-next",
                    prevEl: "#swiper-top .swiper-button-prev",
                },
            },
            cardindex: null,
            showmdtitle: false,
            showmdright: false,
            showmdtop: false,
            showswiper: false,
            showadvantage: false
        };
    },
    created() {
        // this.technologytext = this.technologylist[0].text
        console.log(this.technologytext);
    },
    mounted() {
        this.initSwiper()
        this.$nextTick(() => {
            window.scrollTo(0, 0)
        })
        window.addEventListener("scroll", this.handleScroll);


    },

    methods: {
        handleScroll() {
            // console.log(123213);
            let scrollall = document.documentElement.clientHeight || document.body.offsetHeight

            let scrollTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollall);
            if (scrollTop > scrollall * 0.06) {
                this.showmdtitle = true
            }
            if (scrollTop > scrollall * 0.63) {
                this.showmdright = true
            }
            if (scrollTop > scrollall * 1.23) {
                this.showmdtop = true
            }
            if (scrollTop > scrollall * 1.95) {
                this.showswiper = true
            }
            // if (scrollTop > scrollall * 2.93) {
            //     this.showadvantage = true
            // }
            // if (scrollTop > 1700) {
            //     this.showprocess = true
            // }
            // if (scrollTop > 2500) {
            //     this.showpartner = true
            // }
        },
        hovercard(item) {
            // console.log(item);
            this.cardindex = item.index
            this.modulelist[item.index].runbt = true
            console.log(this.cardindex);

        },
        leavecard(item) {
            this.modulelist[item.index].runbt = false
            this.cardindex = null

        },
        initSwiper() {
            this.swiperOption = {
                // loop: true, //首尾循环
                // autoplay: {
                //   delay: 600,
                //   stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
                //   disableOnInteraction: false, //true：交互后，不继续滚动
                // },
                slidesPerView: 3, //页面中的板块数量
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                freeMode: false, //true:拖到哪就是哪
                spaceBetween: 30, //板块间隔
                grabCursor: false, //true：鼠标为抓手
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true, //true：点击底部小圆点可以跳转页面
                    type: "fraction", //当前页显示 1/7
                },
                // 设置点击箭头
                navigation: {
                    nextEl: "#swiper-top .swiper-button-next",
                    prevEl: "#swiper-top .swiper-button-prev",
                },
            };
        },
        gotomore() {
            this.$router.push('/case');
        }
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    display: none;
}

@keyframes titleShow {
    from {
        transform: translateY(1000%);
    }

    to {
        transform: translateY(0px);
    }
}

@keyframes leftShow {
    from {
        transform: translatex(-100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes rightShow {
    from {
        transform: translatex(100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes fade {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.goup {
    animation: titleShow 0.5s;

}

.goup1 {
    animation: titleShow 1s;

}

.joinleft {
    animation: leftShow 1s;
}

.joinright {
    animation: rightShow 1s;
}

.fade {
    animation: fade 2s;

}

.container {
    display: grid;
    overflow: auto;
    height: 100%;
    margin: 0 auto;
    overflow: visible;

    .core {
        height: 791px;
        background-image: url("../../assets/detailbg.png"); //背景图
        background-size: 100% 100%;
        background-position: center center;

        .main {
            width: 1722px;
            margin: 0 auto;

            .en_title {
                margin-top: 363px;
                font-size: 60px;
                font-weight: 300;
                color: rgba(255, 255, 255, 1);
            }

            .cn_title {
                margin-top: 32px;
                font-size: 48px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }
        }

    }

    .detail {
        // position: relative;
        height: 637px;
        background-color: rgb(255, 255, 255);

        .main {
            width: 1422px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            background-image: url("../../assets/gaoxiao2X.png"); //背景图
            background-position: 192px 49px;
            background-repeat: no-repeat;
            background-size: 504px 498px;

            .detail_left {
                margin-top: 197px;

                .en_title {
                    // font-family:
                    width: 388px;
                    // height: 114px;
                    font-size: 52px;
                    font-weight: 200;
                    color: rgba(115, 115, 115, 1);
                }

                .cn_title {
                    margin-top: 25px;
                    font-size: 36px;
                    font-weight: 400;
                    color: rgba(73, 138, 244, 1);
                }
            }

            .detail_right {
                margin-top: 206px;
                margin-left: 186px;

                .textbox {
                    margin-bottom: 10px;
                    width: 850px;
                    height: 87px;
                    font-size: 18px;
                    font-weight: 400;
                    color: #8c8c8c;

                }
            }
        }
    }

    .moduleui {
        height: 3589px;
        background-image: url("../../assets/mdui.png"); //背景图
        background-repeat: no-repeat;
        background-size: 100%;

        .main {
            width: 1422px;
            margin: 0 auto;

            .ui_title {
                margin-top: 172px;
                font-size: 36px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }

            .ui_img1 {
                margin-top: 130px;

                img {
                    width: 927px;
                }
            }

            .ui_img2 {
                margin-top: 167px;
                display: flex;

                .goos_list {
                    margin-top: 165px;
                    width: 490px;

                    li {
                        list-style: none;
                        margin-bottom: 20px;
                        // width: 366px;
                        height: 43px;
                        font-size: 20px;
                        font-weight: 200;
                        line-height: 43px;
                        color: rgba(255, 255, 255, 1)
                    }
                }

                img {
                    width: 952px;

                }
            }

            .module_title {
                margin-top: 190px;
                font-size: 36px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }

            .module_img {
                margin-top: 148px;
                width: 1417px;
                // margin-top: 226px;
                margin-bottom: 226px;

                img {
                    width: 100%;
                }
            }

        }

        .module_swiper {
            width: 1920px;
            // margin-top: 226px;
            margin: auto;
            height: 484px;


            .swiper-button-prev:after {
                display: none;
            }

            .swiper-button-next:after {
                display: none;
            }

            /*再自定义样式*/
            .swiper-button-prev {
                width: 92px;
                height: 92px;
                background: url('../../assets/detailafter.png') no-repeat;
                bottom: 15px;
                background-size: 100% 100%;
                background-position: center center;

            }

            .swiper-button-next {
                width: 92px;
                height: 92px;
                background: url('../../assets/detailnext.png') no-repeat;

                background-size: 100% 100%;
                background-position: center center;
            }

            .card {
                width: 484.2px;
                height: 515px;
                border-radius: 26px;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);

                .neirong {

                    position: relative;
                    width: 354px;
                    height: 515px;

                    margin-left: 56px;

                    font-size: 18px;
                    font-weight: 400;
                    color: #8c8c8c;
                    // background-color: #ffa2a2;
                }

                .titlebox {
                    display: flex;
                    // justify-content: flex-end;

                    .title {
                        margin-top: 68px;
                        display: flex;
                        width: 100%;
                        // width: 354px;
                        height: 34px;
                        font-size: 24px;
                        color: rgba(37, 128, 219, 1);

                        .tieiletext {
                            flex: 6;
                        }

                        .switch {
                            flex: 1;

                            right: 0;
                            // margin-left: 158px;
                            // text-align: right;
                        }
                    }

                    .hovertitle {
                        color: rgb(255, 255, 255);

                    }
                }

                .text {
                    margin-top: 49px;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 400;
                    color: #8c8c8c;

                }

                .hovertext {
                    color: rgb(255, 255, 255);

                }

                img {
                    position: absolute;
                    right: 0;
                    bottom: 10%;
                    height: 116px;
                }
            }

            .hoverstyle {

                background: linear-gradient(34.99deg, rgba(42, 130, 228, 1) 0%, rgba(42, 130, 228, 0) 100%);
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);

            }
        }

        // background-color: aqua;
        .bgshuhan {
            margin-right: 50px;
            margin-top: 130px;
            height: 176px;
            text-align: right;

            img {
                height: 100%;
            }
        }
    }


    .advantage {
        height: 712px;
        // text-align: center;

        background-color: rgb(255, 255, 255);

        .main {
            width: 1422px;
            margin: 0 auto;
            // display: flex;
            text-align: center;
        }

        .advantage_title {
            margin-top: 90px;
            margin-left: 640px;
            width: 144px;
            height: 51px;
            font-size: 36px;
            font-weight: 400;
            color: rgba(115, 115, 115, 1);
        }

        .advantage_list {
            display: flex;
            margin-top: 55px;
            margin-left: 128px;

            .border_box {
                text-align: center;
                margin-right: 124px;
                width: 378px;
                height: 348px;
                border-radius: 12px;
                border: 1px solid rgba(201, 201, 201, 1);

                .icon {
                    margin-top: 62px;

                    img {
                        height: 101px;
                    }
                }

                .name {
                    margin-top: 50px;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 28px;
                    color: rgba(73, 138, 244, 1);
                }

                .text {
                    margin-top: 19px;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgba(140, 140, 140, 1);
                }
            }
        }
    }

    .footer {
        position: relative;
        height: 300px;
        color: #C4C4C4;
        background-color: #20262d;
    }
}
</style>
